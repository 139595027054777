<template>
  <div class="bt-transaksi pa-5">
    <h6 class="text-h5 font-weight-bold indigo--text text--lighten-1 d-flex align-center">
      <div class="rounded-pill indigo lighten-1 me-2 px-2 d-flex align-center justify-center py-2 elevation-3">
        <v-icon small color="white">mdi-book-multiple</v-icon>
      </div>
      Laporan Transaksi Tabungan
    </h6>
    <div>
      <v-select solo label="Majelis" class="mb-3 mt-3" hide-details :items="opt.rembug"
        v-model="form.data.kode_rembug" />

      <v-select solo label="Produk" class="mb-3" hide-details :items="opt.product" v-model="form.data.kode_produk" />

      <v-row class="mb-0">
        <v-col sm="12" md="6">
          <v-menu v-model="form.data.from_date_menu" :close-on-content-click="false" :nudge-right="40"
            transition="scale-transition" offset-y min-width="auto" class="white">
            <template v-slot:activator="{ on }">
              <v-text-field label="Periode Awal" solo readonly :value="fromDateDisp" v-on="on"></v-text-field>
            </template>
            <v-date-picker locale="en-in" v-model="form.data.from_date" no-title
              @input="form.data.from_date_menu = false"></v-date-picker>
          </v-menu>
        </v-col>
        <v-col sm="12" offset-sm="2" md="6" offset-md="0">
          <v-menu v-model="form.data.thru_date_menu" :close-on-content-click="false" :nudge-right="40"
            transition="scale-transition" offset-y min-width="auto" class="white">
            <template v-slot:activator="{ on }">
              <v-text-field label="Periode Akhir" solo readonly :value="thruDateDisp" v-on="on"></v-text-field>
            </template>
            <v-date-picker locale="en-in" v-model="form.data.thru_date" no-title
              @input="form.data.thru_date_menu = false"></v-date-picker>
          </v-menu>
        </v-col>
      </v-row>

      <v-btn color="blue-grey mt-0" class="mt-n5 mx-auto white--text" elevation="5" block @click="getTransaksiSaving()">
        Search
        <v-icon right>mdi-magnify</v-icon>
      </v-btn>
    </div>

    <v-container class="pa-0 mt-5">
      <v-card class="white elevation-3 rounded-lg pa-3 mb-3">
        <v-row>
          <v-col cols="12">
            <v-text-field color="black" autocomplete="off" hide-details outlined :value="form.data.total_penarikan"
              label="Total Penarikan" disabled />
          </v-col>
          <v-col cols="12">
            <v-text-field color="black" autocomplete="off" hide-details outlined :value="form.data.total_setoran"
              label="Total Setoran" disabled />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-data-table :headers="table_tabungan.headers" :items="table_tabungan.contents" :items-per-page="5"
              class="elevation-1"></v-data-table>
          </v-col>
        </v-row>
      </v-card>
    </v-container>

    <v-snackbar v-model="alert.show" :timeout="5000">
      {{ alert.msg }}
    </v-snackbar>

    <v-overlay v-model="overlay" class="align-center justify-center">
      <v-progress-circular color="primary" size="64" indeterminate></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import helper from "@/utils/helper";
import { mapGetters, mapActions } from "vuex";
import services from "@/services";
export default {
  name: "TabunganTransaksiTabungan",
  components: {},
  data() {
    return {
      table_tabungan: {
        headers: [
          {
            text: 'No',
            align: 'start',
            sortable: false,
            value: 'no',
          },
          { text: 'Tanggal', value: 'trx_date' },
          { text: 'No Rekening', value: 'no_rekening' },
          { text: 'Nama', value: 'nama_anggota' },
          { text: 'Produk', value: 'nama_produk' },
          { text: 'Tipe Transaksi', value: 'nama_trx' },
          { text: 'Debet', value: 'debet' },
          { text: 'Credit', value: 'credit' },
        ],
        contents: [],
      },
      form: {
        data: {
          kode_rembug: null,
          kode_produk: null,
          from_date_menu: false,
          from_date: null,
          thru_date_menu: false,
          thru_date: null,
          total_penarikan: 0,
          total_setoran: 0,
        }
      },
      opt: {
        rembug: [],
        product: [],
      },
      dateShow: false,
      alert: {
        show: false,
        msg: ''
      },
      overlay: false,
    }
  },
  computed: {
    ...mapGetters(["user"]),
    fromDateDisp() {
      return this.form.data.from_date;
      // format/do something with date
    },
    thruDateDisp() {
      return this.form.data.thru_date;
      // format/do something with date
    },
  },
  methods: {
    ...helper,
    async getRembug() {
      try {
        this.overlay = true;
        this.opt.rembug = [];

        const {
          kode_cabang = null
        } = this.user;

        let payload = new FormData();
        payload.append("kode_cabang", kode_cabang);

        const response = await services.rembugRead(payload, this.user.token);
        if (response.status === 200) {
          if (response.data.data.length > 0) {
            response.data.data.map((item) => {
              this.opt.rembug.push({
                text: item.nama_rembug,
                value: item.kode_rembug,
              });
            });
          }
        } else {
          this.alert = {
            show: true,
            msg: response.data.msg || null,
          };
        }
      } catch (error) {
        this.alert = {
          show: true,
          msg: error.message || '',
        };
      } finally {
        this.overlay = false;
      }
    },
    async getProduk() {
      try {
        this.overlay = true;
        this.opt.product = []

        let payload = new FormData()
        const response = await services.produkTabungan(payload, this.user.token)
        if (response.status === 200) {
          if (response.data.data.length > 0) {
            response.data.data.map((item) => {
              this.opt.product.push({
                value: item.kode_produk,
                text: item.nama_produk,
                data: item
              });
            });
          }
        } else {
          this.alert = {
            show: true,
            msg: response.data.msg || null,
          }
        }
      } catch (error) {
        this.alert = {
          show: true,
          msg: error.message || ''
        }
      } finally {
        this.overlay = false;
      }
    },
    async getRekening() {
      try {
        this.overlay = true;
        this.opt.rekening = []

        let payload = new FormData();
        payload.append('no_anggota', this.form.data.no_anggota);

        const response = await services.getRekeningTabungan(payload, this.user.token)
        if (response.status === 200) {
          response.data.data.map((item) => {
            this.opt.rekening.push({
              value: item.no_rekening,
              text: `${item.no_rekening} - ${item.nama_produk}`,
              data: item
            });
          });
        } else {
          this.alert = {
            show: true,
            msg: response.data.msg || null,
          }
        }
      } catch (error) {
        this.alert = {
          show: true,
          msg: error.message || ''
        }
      } finally {
        this.overlay = false;
      }
    },
    async getTransaksiSaving() {
      try {
        this.overlay = true;
        this.table_tabungan.contents = [];

        if (this.form.data.from_date == null && this.form.data.thru_date == null) {
          this.alert = {
            show: true,
            msg: 'Please Select "Periode Awal" & "Periode Akhir"!',
          }
          return;
        }

        let payload = new FormData()
        payload.append('kode_rembug', this.form.data.kode_rembug);
        payload.append('kode_produk', this.form.data.kode_produk);
        payload.append('from_date', this.form.data.from_date);
        payload.append('thru_date', this.form.data.thru_date);

        const response = await services.getLaporanListTransaksiTabungan(payload, this.user.token)
        if (response.status === 200 && response.data.data && response.data.data !== null) {
          const {
            histori,
            total_penarikan,
            total_setoran
          } = response.data.data;

          const contents = histori ? histori.map((item, index) => {
            item.no = index + 1;
            item.trx_date = this.formatDate(item.trx_date);
            return {
              ...item
            }
          }) : [];

          this.form.data.total_penarikan = this.thousandMask(total_penarikan);
          this.form.data.total_setoran = this.thousandMask(total_setoran);
          this.table_tabungan.contents = contents;
        } else {
          this.alert = {
            show: true,
            msg: response.data.msg || null,
          }
        }
      } catch (error) {
        this.alert = {
          show: true,
          msg: error.message || ''
        }
      } finally {
        this.overlay = false;
      }
    },
  },
  mounted() {
    this.getRembug();
    this.getProduk();
  },
};
</script>
